import React from 'react'
import ErrorPage from '@pages/error'

interface Props {
  statusCode?: number | string
}

const ErrorContainer = ({ statusCode = 404 }: Props) => (
  <ErrorPage statusCode={statusCode} />
)

export default ErrorContainer
